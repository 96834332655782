const tableData = [
  {
    id: 1,
    name: 'Recruiting Mangroupsr',
    course: 'Web Desing',
    date: '28/05/2022',
    link: 'https://www.thecampusqdl.com/uploads/files/pdf_sample_2.pdf',
  }, {
    id: 2,
    name: 'Vocês estão avisados',
    course: 'Marketing Digital',
    date: '28/05/2022',
    link: 'https://www.thecampusqdl.com/uploads/files/pdf_sample_2.pdf',
  }, {
    id: 3,
    name: 'Recruiting Mangroupsr',
    course: 'OOP Programming',
    date: '28/05/2022',
    link: 'https://www.thecampusqdl.com/uploads/files/pdf_sample_2.pdf',
  }, 
]

export { tableData };
